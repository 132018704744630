import { useStaticQuery, graphql } from "gatsby"
export const useServiceMetaData = () => {
  const { site } = useStaticQuery(
    graphql`
      {
      site {
        siteMetadata {
          title
          siteUrl
          siteLogo
          businessAddress
          businessNumber
          geoLon
          geoLat
          geoRad
        }
      }
    }
    `
  )
  return site.siteMetadata
}