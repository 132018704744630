import * as React from 'react'
import { useServiceMetaData } from '../../components/hooks/schema/servicesSchema'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import SplashTwo from '../../components/splashTwo'

const FireDmgPage = () => {
  const data = useServiceMetaData()

  const ServiceSchema = ` 
    {
      "@context": "https://schema.org/",
      "@type": "Service",
      "serviceType": "fire damage repair services",
	  "alternateName": "fire damage restoration services",
      "provider": {
        "@type": "LocalBusiness",
        "name": "${data.title}",
        "image": "${data.siteUrl}${data.siteLogo}",
        "address": "${data.businessAddress}",
        "telephone": "${data.businessNumber}",
        "priceRange": "$$ - $$$$"
      },
      "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": ${data.geoLon}
                "longitude": ${data.geoLat}
            },
            "geoRadius": ${data.geoRad}
        },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "fire damage repair services",
        "itemListElement": [
          {
            "@type": "OfferCatalog",
            "name": "fire damage repair services",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "fire damage repair services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "fire damage restoration services"
                }
              }
            ]
          }
        ]
      }
    }
  `

  return (
    <Layout>
      <Seo
        title="Fire Damage Restoration Services"
        description="Fire damage restoration and repair services, including getting rid of that lingering smoke smell."
        canonical="https://ecotechrestoration.ca/services/fire-damage-repair-and-restoration/"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ServiceSchema }}
      />
      {/* Heros */}
      <div className="heroWrapper">
        <StaticImage
          className="heroImage"
          src="../../images/heros/fire.jpg"
          quality={95}
          alt="Fire Damage Restoration Services"
        />
        <div className="overlay" />
        <h1 className="services__heading heroText">
          Smoke &amp; Fire Damage Restoration
        </h1>
      </div>

      <SiteWidthWrapper>
        <CallOut>
          Do you have a Smoke or Fire Damage related Restoration Emergency?
        </CallOut>
        <p>
          The trauma that accompanies a fire is not extinguished with the
          flames. Smoke pollution travels through vent systems and sticks to and
          discolours building surfaces and valuables. Water and extinguisher
          chemicals combine with soot and ash to create a messy sludge that
          oozes into cracks, crevices, and upholstery. Charred and consumed
          materials can compromise the structural integrity of the building
          itself and create hazards for restoration efforts.
        </p>
        <p>
          Ecotech's licensed and certified fire restoration services can help
          ease some of your pain by working with your insurance company to
          safely clean, decontaminate, and repair your property like new!
        </p>
        <p>
          We will immediately dispatch our fire damage restoration technicians
          to your property as soon as you call our office. It doesn't matter
          what kind of fire disaster you find yourself dealing with. Our IICRC
          certified fire damage restoration team will be there to provide you
          with services such as:
          <ul>
            <li>Smoke deodorization</li>
            <li>Ash and tar cleanup</li>
            <li>Removal of burned possessions</li>
            <li>Replacement of fire damaged flooring</li>
            <li>
              Restoration of smoke-stained walls and ceiling • Water damage
              restoration
            </li>
            <li>Overall comprehensive home damage repair</li>
            <li>Cleanup of fire retardant chemicals</li>
          </ul>
        </p>
        <p>
          As soon as professionals have safely put out the flames, we will kick
          the process off with a visual home inspection.
        </p>
        <h2>
          Our Company Will Restore Your Home From All Fire Related Damage,
          Including Water
        </h2>
        <p>
          Fire damage restoration goes hand-in-hand with water damage
          restoration because fires are put out with sprinklers and hoses. After
          a fire has taken place, our fire remediation crew will safely and
          quickly restore the home from all damage.
        </p>
        <p>
          Our team is trained with water damage restoration, arriving equipped
          with top-of-the-line equipment to perform advanced home restoration
          and renovation where necessary.
        </p>
        <p>
          Our years of experience within the home restoration industry
          guarantees your property will be free of smoke stains, lingering
          odours, ash, tar, and any other signs of fire damage after we're
          finished. We will get your home back to its as-new condition.
        </p>
        <p>
          We understand that this can be an overwhelming experience. We will
          make sure you feel at home if you're able to remain in your home
          during the restoration process and back at home as soon as possible if
          you are not.
        </p>
        <h2>We Know How To Turn Smoke And Fire Damage Into A Fresh Start</h2>
        <p>
          We will not allow anything to go unnoticed and make sure that what
          seems like permanent damage will be repaired. We understand that at
          first, you might feel like nothing will be the same again, but
          understand that our fire damage restoration team will work hard to
          turn this experience into a positive one.
        </p>
        <p>
          Providing you with an incredibly high level of customer service is
          essential to us. We take pride in our work and want to make sure you
          remain proud of your space.
        </p>
        <p>Call Ecotech Restoration for your Fire Damage Restoration needs.</p>
        <SplashTwo title="Fire Damage Restoration" />
      </SiteWidthWrapper>
    </Layout>
  )
}

export default FireDmgPage
