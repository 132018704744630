import * as React from "react"

import SiteWidthWrapper from "./wrappers/siteWidthWrapper"

import {
	SiteWidthWrapperFlex,
	SplashTwoP_A,
	SplashTwoP_B,
} from "./splashTwo.module.scss"

const SplashTwo = ({ title }) => {
	return (
		<SiteWidthWrapper>

			<div className={SiteWidthWrapperFlex}>

				{/* styled P */}
				<div className={SplashTwoP_A}>
					We Are<br />Your Best Choice<br />For {title}
				</div>

				<div className={SplashTwoP_B}>
					Fully Licensed &amp; Insured<br />
					Available 24/7<br />
					Have a Rapid Response Team<br />
					We Work With Your Insurance Company To Resolve Your Insurance Claim
			</div>
			</div>

		</SiteWidthWrapper>
	)
}

export default SplashTwo